import React, { useState } from "react";
import "./BackedBy.css";
import bg from "../../../../assets/tatsam_collective/backed-by-bg.png";
import person4 from "../../../../assets/tatsam_collective/Anupal Banerjee 1.png";
import person5 from "../../../../assets/tatsam_collective/Anurag Malik 1.png";
import person2 from "../../../../assets/tatsam_collective/Arvind Laddha 1.png";
import person3 from "../../../../assets/tatsam_collective/Ashish Goel 1.png";
import person1 from "../../../../assets/tatsam_collective/Z 1.png";
import logoT from "../../../../assets/tatsam_collective/logo1.png";
import logoB from "../../../../assets/tatsam_collective/logo2.png";
import frame1 from "../../../../assets/tatsam_collective/Frame 24.png";
import frame2 from "../../../../assets/tatsam_collective/Frame 25.png";
import frame3 from "../../../../assets/tatsam_collective/Frame 26.png";
import frame4 from "../../../../assets/tatsam_collective/Frame 27.png";
import arrow from "../../../../assets/tatsam_collective/arrow.png";
import rightLeaf from "../../../../assets/tatsam_collective/right_leaf2.png";
import ResistrationForm from "../resistration-form/ResistrationForm";

function BackedBy() {
  const [isOpen, setIsOpen] = useState(false);
  const [form, setForm] = useState();

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const data = [
    {
      img: person1,
      name: "Arjun Malhotra",
      desc: "Founder & Former Vice chairman HCL",
    },
    {
      img: person2,
      name: "Arvind Laddha",
      desc: "India CEO, Mercer",
    },
    {
      img: person3,
      name: "Ashish Goel",
      desc: "Founder and CEO Urban Ladder",
    },
    {
      img: person4,
      name: "Anupal Banerjee",
      desc: "Global CHRO, TATA Technologies",
    },
    {
      img: person5,
      name: "Anurag Malik",
      desc: "EY India managing partner, Workforce Advisory",
    },
  ];

  const imageData = [
    {
      name: "Off My Chest Channel",
      img: frame1,
    },
    {
      name: "Ask a Therapist Channel",
      img: frame2,
    },
    {
      name: "Navigating Relationships Channel",
      img: frame3,
    },
    {
      name: "Stress Less Channel",
      img: frame4,
    },
  ];
  return (
    <section className="backed-by">
      <img src={rightLeaf} alt="" className="right-leaf" />
      <div className="container">
        <div
          className="backed-by-container"
          style={{
            background: `url(${bg})`,
            backgroundPosition: "0 0px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <span
            className="title"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-offset="100"
          >
            We Are Backed By The Best
          </span>
          <div className="brand-area">
            <img
              src={logoT}
              data-aos="fade-right"
              data-aos-duration="1000"
              className="logo1"
              alt=""
            />
            <img
              src={logoB}
              data-aos="fade-left"
              data-aos-duration="1000"
              className="logo2"
              alt=""
            />
          </div>
          <div className="person-container">
            {data.map((person, i) => {
              return (
                <div
                  className="person"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                  aos-delay="200"
                >
                  <img src={person.img} alt="" />
                  <span className="name">{person.name}</span>
                  <span className="desc">{person.desc}</span>
                </div>
              );
            })}
          </div>
          <span className="heading" data-aos="fade-up" data-aos-duration="1000">
            Get access to our expert wellbeing coaches to support you in
            answering your questions.
          </span>
          <div className="img-container">
            {imageData.map((item) => {
              return (
                <img
                  onClick={() => {
                    setForm(item.name);
                    togglePopup();
                  }}
                  data-aos="fade-left"
                  data-aos-duration="1000"
                  src={item.img}
                  alt=""
                />
              );
            })}
          </div>
          <div className="arrow">
            <img
              src={arrow}
              onClick={() => {
                setForm();
                togglePopup();
              }}
              alt=""
            />
          </div>
        </div>
      </div>
      {isOpen && (
        <ResistrationForm
          data-aos="zoom-in-up"
          data-aos-duration="2000"
          handleClose={togglePopup}
          formName={form}
        />
      )}
    </section>
  );
}

export default BackedBy;
