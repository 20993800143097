import React from 'react';
import data from '../../../../data/data';
import './Main.css';
import left_img from '../../../../assets/left.png';
import right_img from '../../../../assets/right.png';


function Main() {

    const items=data.map((item,index)=>{
        return <>
            <div className="card" key={item.id}>
                <img src={item.img} alt="" />
                <h3>{item.name}</h3>
                <p>{item.description}</p>
            </div>
        </>
    })
    return (
        <>
            <section className="main-section">
               
               <div className="container">
               <h4>We understand that true wellbeing comes from a strong foundation. So we are <br/>creating solutions that work at each level of an organisation, and that can make <br/>the individuals feel more :</h4>
               </div>
    
                
                <div className="box">
                    {items}
                </div>
                
                <div className="left-img">
                    <img src={left_img} alt="" />
                </div>
                <div className="right-img">
                    <img src={right_img} alt="" />
                </div>
            </section>
        </>
    )
}

export default Main
