import React,{useEffect} from 'react';
import '../footer/Footer.css';
import footer_img from '../../../../assets/Group 1 (1).png';
import {Link} from 'react-router-dom';

function Footer() {
   
    return (
        <>
            <section className="footer">
                <div className="container">
                    <div className="footer-content">
                        <h1>Do you want to change the way workplaces foster<br /> wellbeing for their employees?</h1>
                        <p>We are always growing our team, and we are keenly looking for people <br />with interest in psychology and tech.</p>
                        <a className="button" href="https://angel.co/company/tatsam-wellness/jobs" target="_blank">Join us</a>
                    </div>
                </div>
                <div className="bottom-img">
                    <img src={footer_img} alt="" />
                </div>
                <div className="policy-tag">
                    <span className="copy-right"> <span>©</span> 2020-2021 PureSpace Technologies Pvt. Ltd.</span>
                    <Link to="/privacy-policy"  className="privacy-policy">Our Privacy Policy</Link>
                </div>
            </section>
        </>
    )
}

export default Footer
