import React, { useState } from "react";
import "./UpcommingEvent.css";
import bg from "../../../../assets/tatsam_collective/event-bg.png";
import event1 from "../../../../assets/tatsam_collective/event1.png";
import event2 from "../../../../assets/tatsam_collective/event2.png";
import event3 from "../../../../assets/tatsam_collective/event3.png";
import leftLeaf from "../../../../assets/tatsam_collective/left_leaf.png";
import rightLeaf from "../../../../assets/tatsam_collective/right_leaf2.png";
import ResistrationForm from "../resistration-form/ResistrationForm";
import { Link } from "react-router-dom";

function UpcommingEvent() {
  const [isOpen, setIsOpen] = useState(false);
  const [form, setForm] = useState();

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  return (
    <section className="upcomming-event">
      <img src={bg} alt="" className="bg-image" />
      <span className="title" data-aos="fade-up" data-aos-duration="1000">
        Register For Our Upcoming Events
      </span>
      <div className="container">
        <div className="event-card-area">
          <img
            src={event1}
            data-aos="fade-left"
            data-aos-duration="1000"
            onClick={() => {
              togglePopup();
              setForm("Vent-nesday Session");
            }}
            alt=""
          />
          <img
            src={event2}
            data-aos="fade-left"
            data-aos-duration="1000"
            onClick={() => {
              togglePopup();
              setForm("Ask Me Anything Session");
            }}
            alt=""
          />
          <img
            src={event3}
            data-aos="fade-left"
            data-aos-duration="1000"
            onClick={() => {
              togglePopup();
              setForm();
            }}
            alt=""
          />
        </div>
      </div>
      <h2>See You In The Community!</h2>
      <div className="policy-text">
        <h3>© 2020-2021 PureSpace Technologies Pvt. Ltd.</h3>
        <Link to="/privacy-policy">Our Privacy Policy</Link>
      </div>
      <img src={leftLeaf} alt="" className="left-leaf" />
      <img src={rightLeaf} alt="" className="right-leaf" />
      {isOpen && <ResistrationForm handleClose={togglePopup} formName={form} />}
    </section>
  );
}

export default UpcommingEvent;
