import HomePage from './component/home-page/HomePage';
import ThankYouPage from './component/thankyou-page/ThankYouPage';
import Reward from './component/reward/Reward';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import ScratchBox from './component/scratch_card/ScratchBox';
import Idea1 from './component/idea-1/Idea1';
import PerformActivity1 from './component/performActivity/PerformActivity1';
import PrivacyPolicy from './component/privacy-policy/PrivacyPolicy';
import ScrollToTop from './ScrollToTop';
import TatsamCollective from './component/tatsam-collective/TatsamCollective';
import ThankYou from './component/thank-you/ThankYou';
function App() {


  return (
    <div className="App">
      <Router>
        <ScrollToTop>
          <Switch>
            <Route exact path='/' component={TatsamCollective}></Route>
            <Route exact path='/privacy-policy' component={PrivacyPolicy}></Route>
            <Route exact path='/thank-you' component={ThankYou}></Route>
            {/* <Route exact path='/perform-activity/:activityName' component={PerformActivity1}></Route>
             <Route exact path='/redeem-free-consultation' component={Idea1}></Route>
             <Route exact path='/free-reward/scratch' component={ScratchBox}></Route>
             <Route exact path='/tatsam-collective' component={TatsamCollective}></Route> */}
            {/* {/* <Route exact path="/thank-you/reward/:reward_name" component={Reward} /> */}
            <Route path="/*">
              <Redirect to="/" />
            </Route>

          </Switch>
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default App;

