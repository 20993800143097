import React, { useState } from 'react';
import mountain from '../../../../assets/Group 95 (2).png';
import '../header/Header.css';
import formBg from '../../../../assets/tatsam_collective/Registration Form.png';

function Header() {

    function openForm() {
        document.getElementById("myForm").style.display = "block";
    }
    function closeForm() {
        document.getElementById("myForm").style.display = "none";
    }


    const [email, setEmail] = useState("");

    const handleSubmit = (evt) => {
        evt.preventDefault();
        setvalue(email);
        setEmail("");
        closeForm();
    }
    function setvalue(email_value) {
       window.analytics.identify(email_value, {}, {
            integrations: {
              'All': false,
              'Customer.io': true
            }
          });
        
        window.analytics.track('Form Submitted', {
            intent: "Landing Page Lead"
        }, { 
            integrations: {
              'All': false,
              'Slack': true
            //   'Customer.io': false
            },
          });

    }
    return (
        <>
            <section className="header">
            <div className="header-content">
                        <h1>True wellbeing, from the inside</h1>
                        <p>The scientific approach to workplace wellbeing</p>
                        <a href="#" onClick={() => {
                            openForm();
                        }}>Join the waitlist</a>
                    </div>
                
                    <img src={mountain} alt="" className='header-bg'/>
                
                <div class="form-popup" id="myForm" >
                    <div className="form-area">
                        <div className="cancel-area" onClick={() => closeForm()}></div>
                    <form class="form-container" onSubmit={handleSubmit} style={{backgroundImage:`url("${formBg}")`,backgroundSize:'cover',backgroundRepeat:'no-repeat'}}>
                        
                        <input type="text" placeholder="Enter Your Email" name="email" value={email}
                            onChange={e => setEmail(e.target.value)} required />

                        <button type="submit" class="btn">Submit</button>
                        <a onClick={() => closeForm()} className="cancel">cancel</a>
                    </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Header
