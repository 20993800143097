import React from 'react';
import '../navbar/Navbar.css';
import logo from '../../assets/Group.svg';
import line from '../../assets/Vector.svg';
import tatsam from '../../assets/Group (1).svg';

function Navbar() {
    return (
        <>
            <div className="navbar">
                <nav>
                    <div className="logo">
                        <img src={logo} alt="" />
                    </div>
                    <div className="line">
                        <img src={line} alt="" />
                    </div>
                    <div className="tatsam">
                        <img src={tatsam} alt="" />
                    </div>
                </nav>
            </div>
        </>
    )
}

export default Navbar
