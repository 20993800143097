import React from "react";
import "../what-to-expect/WhatToExpect.css";
import poster1 from "../../../../assets/tatsam_collective/poster1.png";
import poster2 from "../../../../assets/tatsam_collective/poster2.png";
import poster3 from "../../../../assets/tatsam_collective/poster3.png";
import leftLeaf from "../../../../assets/tatsam_collective/left_leaf.png";

function WhatToExpect() {
  const data = [
    {
      img: poster1,
      title: "A Safe Space to vent, share and seek help",
      subTitle: `Our channel is a safe space for you to talk, express yourself  and share your deepest thoughts. We'll always listen, if you want, we'll talk too.  In addition to the channel #off-my-chest where you can share your long-standing baggage, what made you angry, why you cheated, happy thoughts, recent trauma or your latest work catastrophe. Posting here may help you find meaning, understand yourself a little better and provide relief.`,
    },
    {
      img: poster2,
      title: "Live Sessions with a Therapist",
      subTitle: `To support those of us who don't yet feel comfortable seeking help from mental health providers in-person — #ask-a-therapist is where you can get impartial advice and guidance from a qualified mental health professional.`,
    },
    {
      img: poster3,
      title: "Peer-led Community Support",
      subTitle: `Our aim is to offer a mutually supportive peer community where deeply emotional things you can't tell other people can be shared. You can find a channel related to specific concerns like loneliness and social anxiety, stress, relationships and sexual health or just post in the channel called #random. We also have channels for positive psychology, where you can express self-affirmation, pride, develop fruitful habits and share gratitude, and exchange self-care tips because we believe in holistic development and working towards a better version of ourselves.`,
    },
  ];
  return (
    <section className="what-to-expect">
      <img src={leftLeaf} alt="" className="left-leaf" />
      <span
        className="heading"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-offset="100"
      >
        What To Expect
      </span>
      <div className="container">
        <div className="cards-area">
          {data.map((item) => {
            return (
              <div
                className="event-card"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="text-title">
                  <span className="title">{item.title}</span>
                  <span className="subTitle">{item.subTitle}</span>
                </div>
                <img src={item.img} className="event-img" alt="" />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default WhatToExpect;
