import React from 'react';
import '../thankyou-page/ThankYouPage.css';
import logo from '../../assets/logo.svg';
import footer_img from '../../assets/bottom.png';

function ThankYouPage() {

    return (
        <>
            <section className="thank-you" style={{ backgroundImage: `url(${footer_img})` }}>
                <img src={logo} alt="" className="logo" />
                <div className="container">
                    <div className="text-content">
                        <h2>Thank you for your interest!</h2>
                        <p>
                            We couldn’t be more excited!! We will notify you once your access has been approved. We have opened only limited slots available for now.
                        <br/><br/>
                            Want to experience more guidance and a daily holistic wellbeing plan? You can put yourself at the top of the guest list of our app. Just send us an email at <a href="mailto:bhavna@tatsam.in">bhavna@tatsam.in</a>
                        </p>
                    </div>
                </div>
                {/* <div className="bottom-image">
                    <img src={footer_img} alt="" />
                </div> */}
            </section>

        </>
    )
}

export default ThankYouPage
