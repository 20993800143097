import React, { useState } from 'react';
import '../idea-1/Idea1.css';
import Navbar from '../navbar/Navbar';
import idea1Bg from '../../assets/idea1-bg.png';
import plus from '../../assets/plus.png';
import scratchData from '../../data/scratch_card_data';
import qs from 'qs';

function Idea1({location}) {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    const email_value = query.email;
    console.log(email_value);
    const [item, setItem] = useState({});
    console.log(item);
    
    var click=(index,reward_name)=>{
        const button=document.getElementById(reward_name);
        button.innerHTML="Claimed!";
        button.style.color='rgba(131, 224, 221, 1)';
        
        if (!email_value) {
            return;
          }
          window.analytics.identify(email_value, {}, {
            integrations: {
              'All': false,
              'Customer.io': true
            }
          });
  
          window.analytics.track('Reward Unlocked', {
            reward: scratchData[index].reward_name,
            claimed_from: "Survey Close Thank You"
          }, {
            integrations: {
              'All': false,
              'Customer.io': true
            },
          });
    }
    const ListOfCard = scratchData.map((data, i) => {
        return (
            <article class="card" key={data.id} data-key={i}>
                <img src={data.img} alt="" />
                <div className="card-text">
                    <p>{data.reward_name}</p>
                    <a id={data.reward_name} onClick={() => click(i,data.reward_name)}>Claim It</a>
                </div>
            </article>
        );

    })
    return (
        <section className="idea-1" style={{ background: `url(${idea1Bg})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom', backgroundSize: '100vw 65vh', backgroundAttachment: 'fixed' }}>
            <img src={plus} alt="" className="plus-img" />
            <div className="nav">
                <Navbar />
            </div>
            <div className="container">
                <div className="top-content">
                    <h1>Congratulations!!</h1>
                    <p>Because we appreciate your time, we will let you choose one of our amazing <br /> one-to-one sessions as our way of saying thank you.</p>
                </div>
            </div>
            <div className="container">
                <section className="cards">
                    {ListOfCard}
                </section>
            </div>
        </section>
    )
}

export default Idea1
