import React,{useEffect} from 'react'
import WhyTatsam from './component/why-tatsam-collective/WhyTatsam'
import WhatToExpect from './component/what-to-expect/WhatToExpect'
import BackedBy from './component/backed-by/BackedBy'
import UpcommingEvent from './component/upcomming-events/UpcommingEvent'
import PrivacyTile from './component/privacy-tile/PrivacyTile'
import AOS from "aos";
import "aos/dist/aos.css";
import CollectiveHeader from './component/header/CollectiveHeader'

function TatsamCollective() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
    return (
        <div style={{overflowX:'hidden',position:'relative'}}>
            <CollectiveHeader/>
            <WhyTatsam/>
            < WhatToExpect/>
            <PrivacyTile/>
            <BackedBy/>
            <UpcommingEvent/>
        </div>
    )
}

export default TatsamCollective
