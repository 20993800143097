import React,{useEffect} from 'react'
import './PrivacyPolicy.css';
import Navbar from '../navbar/Navbar';
import footer_img from '../../assets/Group 1 (1).png';

function PrivacyPolicy() {
    return (
        <section className="privacy-policy">
            <Navbar/>
            <div className="WordSection1">
        <p className="MsoNormal" align="center" style={{textAlign: 'center', lineHeight: '150%'}}><b><span lang="EN-IN" style={{fontSize: '16.0pt', lineHeight: '150%', }}>PRIVACY
              POLICY</span></b></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>This
            privacy policy (“Policy”) describes how <b>PureSpace Technologies Pvt. Ltd.</b>
            (herein and after referred to as “<b>Tatsam</b>”, “We”, “us”, “our”) collects,
            uses, and shares personal information of users of our website, </span><span lang="EN-IN"><a href="https://www.tatsam.in/"><span style={{fontSize: '14.0pt', lineHeight: '150%', }}>https://www.tatsam.in/</span></a></span><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>,
            and any of our affiliated “micro-sites” set up for our customers (together, the
            “Site”), as well as associated products and services, including, without
            limitation, mobile applications developed by Tatsam (together, the “Services”),
            and applies to information that we collect through our Site and Services as
            well as information you provide to us directly. This Policy also applies to any
            of our other websites that post this Policy. </span></p>
        <p className="MsoNormal" style={{marginTop: '.3in', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', textAlign: 'justify', lineHeight: '150%', background: 'white'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%',  color: 'black'}}>At Tatsam, your privacy is always a priority. This Privacy Policy
            explains (i) what information we collect through your access and use of our
            Service (ii) the use we make of such information; and (iii) the security level
            we provide for protecting such information. This Privacy Policy also sets out
            without limitation, how Tatsam, collects and uses information from customers
            and other individuals who access or use our Services. By downloading our mobile
            application and/or using our Services, you understand that we will collect and
            use your information as described in this Privacy Policy and </span><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%',  color: 'black'}}>accept the practices outlined in this Privacy Policy.</span></p>
        <p className="MsoNormal" style={{marginTop: '.3in', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', textAlign: 'justify', lineHeight: '150%', background: 'white'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%',  color: 'black'}}>If you or your organization has an individual agreement with us,
            that agreement may have privacy terms that also apply to the information you
            provide to us under that agreement. Please review the terms in that agreement
            because they may be different or more restrictive than the terms in this Privacy
            Policy.</span></p>
        <p className="MsoNormal" style={{marginTop: '.3in', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', textAlign: 'justify', lineHeight: '150%', background: 'white'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%',  color: 'black'}}>This Privacy Policy (“Policy”) does not apply to any third-party
            websites and apps that you may use, including any that are linked to in our
            Services. You should review the terms and policies for third-party websites and
            apps before clicking on any links.</span></p>
        <p className="MsoNormal" style={{marginTop: '.3in', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', textAlign: 'justify', lineHeight: '150%', background: 'white'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%',  color: 'black'}}>If you do not agree to this Policy, please do not use our Sites or
            applications or Services. </span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>&nbsp;</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><b><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>1.
              WHO ARE WE</span></b></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>Tatsam
            is a Indian Company registered under the Companies Act, 2013, providing digital
            platform for providing mental health and wellness services for individuals and
            workplaces. It combines eastern (e.g. Yoga) and western philosophies (e.g.
            clinical psychology) of wellbeing to deliver personalised and holistic programs
            for the modern professionals to achieve a balanced and stress free life. </span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>More
            specifically, the application allows individuals to enter information on 4 main
            indicators - life evaluations, positive emotions, negative emotions and
            physical health. It then delivers a personalised pathway comprising of inter-disciplinary
            and complementary experiences (activities around mental, physical, spiritual
            and environmental) targeted at better mental health and wellbeing. For the
            organisation, the product then aggregates data on these diagnostics and their
            consequent progress, using which employers can identify macro-level
            interventions - policies, programs, targeted trainings etc. to create an
            ecosystem of wellbeing. In the long term, they can also get predictive
            recommendations to take proactive actions on health and wellbeing in the
            workplace.</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>At
            times, we may work with contracted third party, in addition to medical and
            mental health experts, to support delivery of our services or to deliver
            services to you directly. To the extent that there is a conflict between Tatsam’s
            Privacy Policy and that of a contracted third party, Tatsam’s Privacy Policy
            will apply.</span></p>
        <p className="MsoNormal" style={{marginTop: '.3in', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', textAlign: 'justify', lineHeight: '150%', background: 'white'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%',  color: 'black'}}>The Company is domiciled in India at the following address:</span></p>
        <p className="MsoNormal" style={{marginTop: '.3in', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', lineHeight: '150%', background: 'white'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%',  color: 'black'}}>Purespace Technologies Private Limited<br />
            90b Delhi - Jaipur Expy Sector 18, Gurugram - 122001, Haryana, India</span></p>
        <p className="MsoNormal" style={{marginTop: '.3in', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', textAlign: 'justify', lineHeight: '150%', background: 'white'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%',  color: 'black'}}>All data storage infrastructure is located solely within India,
            and thus governed by the laws and regulations of India. </span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>&nbsp;</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><b><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>2.
              DATA WE COLLECT</span></b></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%',  color: 'black'}}>Our company’s overriding policy is to collect as little user
            information as possible to ensure a completely private user experience when
            using the Service. Our data collection is limited to the following:</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>a.
            Account Creation: </span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%',  color: 'black'}}>Any user signing up for a User Account on Tatsam is requested for
            the following personal information:</span></p>
        <p className="MsoListParagraphCxSpFirst" style={{marginTop: '.3in', marginRight: '0in', marginBottom: '0in', marginLeft: '1.0in', textAlign: 'justify', textIndent: '-.25in', lineHeight: '150%', background: 'white'}}><span style={{fontSize: '14.0pt', lineHeight: '150%', fontFamily: 'Symbol', color: 'black'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style={{fontSize: '14.0pt', lineHeight: '150%',  color: 'black'}}>Name </span></p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginTop: '.3in', marginRight: '0in', marginBottom: '0in', marginLeft: '1.0in', textAlign: 'justify', textIndent: '-.25in', lineHeight: '150%', background: 'white'}}><span style={{fontSize: '14.0pt', lineHeight: '150%', fontFamily: 'Symbol', color: 'black'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style={{fontSize: '14.0pt', lineHeight: '150%',  color: 'black'}}>Contact Number</span></p>
        <p className="MsoListParagraphCxSpMiddle" style={{marginTop: '.3in', marginRight: '0in', marginBottom: '0in', marginLeft: '1.0in', textAlign: 'justify', textIndent: '-.25in', lineHeight: '150%', background: 'white'}}><span style={{fontSize: '14.0pt', lineHeight: '150%', fontFamily: 'Symbol', color: 'black'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style={{fontSize: '14.0pt', lineHeight: '150%',  color: 'black'}}>Email id</span></p>
        <p className="MsoListParagraphCxSpLast" style={{marginTop: '.3in', marginRight: '0in', marginBottom: '0in', marginLeft: '1.0in', textAlign: 'justify', textIndent: '-.25in', lineHeight: '150%', background: 'white'}}><span style={{fontSize: '14.0pt', lineHeight: '150%', fontFamily: 'Symbol', color: 'black'}}>·<span style={{font: '7.0pt "Times New Roman"'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span></span><span style={{fontSize: '14.0pt', lineHeight: '150%',  color: 'black'}}>Gender</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>&nbsp;</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>b.
            Information We Get From Other Parties:</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>We
            may get information about you from other sources. We may add this to
            information we get from this Site and/or through our Services. For example, you
            may also be able to access your Tatsam account by signing on through various
            sites such as Google. Your participation with the services provided on these
            platforms is voluntary. If you choose to sign on using this service, Tatsam
            will collect certain information from your account including your public
            profile, user name, email address, birthday, stated location city, contact
            lists, and other interactions on that platform (such as interests and likes).
            The information we may have access to will vary by platform and is controlled
            by privacy settings on that platform and your choices on that platform. Your
            use of services on third party platforms are governed by the privacy statement
            and other terms of use for that third party platform, until such information is
            shared with us, and then such information is also subject to this Policy.</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>&nbsp;</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>c.
            Information collected when you visit </span><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%',  color: 'black'}}>our
            Sites or use our Applications:</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>We
            automatically log information about you and your computer, phone, tablet, or
            other devices you use to access the Site and/or Services. For example, when
            visiting our Site or when using Tatsam’s mobile applications, we log your
            computer or device identification, operating system type, network information,
            geo location, browser type, browser language, the website you visited before
            browsing to our Site, pages you viewed, how long you spent on a page, access
            times and information about your use of and actions on our Site or in Tatsam’s
            mobile applications. How much of this information we collect depends on the
            type and settings of the device you use to access the Site and/or Services.</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>&nbsp;</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>d.
            Information collected from you:</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>If
            you choose to interact on the Site and/or through the Services (such as by
            registering; using our Services; completing questionnaires, surveys, service
            contacts, or requests for information) Tatsam will collect the personal information
            that you provide. This includes answers to any questionnaires, your
            preferences, activities performed, feedback provided by you and wellbeing
            metrics that we calculate from these. If you provide us with personal
            information regarding another individual, it is assumed that you have that
            person’s consent to give us their personal information.</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>&nbsp;</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>e.
            Information we get from your employer:</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>In
            case you are an employee of an organization that has tied up with us for our
            Services for its Employees, we may receive information from your employer to
            enable us to provide you our services, to contact you in order to inform you of
            the availability of the Tatsam services, and to help us measure the
            effectiveness of the benefits of Tatsam services.</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>&nbsp;</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>f.</span><span lang="EN-IN"> </span><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>Transactional information: </span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>On
            your request, we would be collecting data required to schedule sessions with
            Counsellors/Practitioners. </span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>&nbsp;</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>g.
            Cookies and Other Tracking Technologies:</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>We
            and our authorized partners may use cookies and other information gathering technologies
            for a variety of purposes. These technologies may provide us with personal
            information, information about devices and networks you utilize to access our
            Sites, and other information regarding your interactions with our Sites. For
            detailed information about the use of cookies in the Sites, please read and
            review our Cookie Policy.</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>We
            partner with third parties to either display advertising on the Sites or to
            manage our advertising on other sites. Our third-party partners may also use
            technologies such as cookies to gather information about your activities on our
            Sites and other sites in order to suggest advertisements based upon your
            browsing activities and interests. If you wish to not have this information
            used for the purpose of serving you interest-based ads, you may opt-out by
            clicking here (or if located in the European Economic Area you may opt-in the
            use of cookies, by clicking here).</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>Web
            beacons, tags and scripts may be used on our Sites or in email or other
            electronic communications we send to you. These assist us in delivering
            cookies, counting visits to our Sites, understanding usage and campaign
            effectiveness and determining whether an email has been opened and acted upon.
            We may receive reports based on the use of these technologies by our
            third-party service providers on an individual and aggregated basis.</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>We
            use Local Storage Objects (“LSOs”) such as HTML5 to store content information
            and preferences. Various browsers may offer their own management tools for
            removing HTML5 LSOs. Third parties with whom we partner to provide certain
            features on our Sites or to display advertising based upon your web browsing
            activity use LSOs such as HTML5 to collect and store information.</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>&nbsp;</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>h.
            Logs and Analytics Information:</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>As
            is true with most websites and services delivered over the Internet, we gather
            certain information and store it in log files when you interact with our Sites
            and Services. This information includes internet protocol (IP) addresses as
            well as browser type, internet service provider, URLs of referring/exit pages,
            operating system, date/time stamp, information you search for, locale and
            language preferences, identification numbers associated with your devices, your
            mobile carrier, and system configuration information. Occasionally, we connect
            personal information to information gathered in our log files as necessary to
            improve our Sites and Services. In such a case, we would treat the combined
            information in accordance with this Policy.</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>&nbsp;</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><b><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>3.
              WHY IS THIS DATA REQUIRED AND/OR PROCESSED AND LEGAL BASIS OF PROCESSING</span></b></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>How
            we use the information we collect depends in part on which Services you use,
            how you use them, and any preferences you have communicated to us. Below are
            the specific purposes for which we use the information we collect about you. We
            use your personal information as follows:</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>a.
            General Uses:</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>The
            Legal basis for collecting your information is because we use the information
            to provide the Services to you</span><span lang="EN-IN">. </span><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>We
            may use the information we collect about you (including personal information,
            to the extent applicable) for a variety of purposes, including to (a) provide,
            operate, maintain, improve, and promote the Services; (b) enable you to access
            and use the Services; (c) process and complete transactions, and send you
            related information, including purchase confirmations and invoices; (d) send
            transactional messages, including responses to your comments, questions, and
            requests; provide customer service and support; and send you technical notices,
            updates, security alerts, and support and administrative messages; (e) send
            promotional communications, such as providing you with information about
            products and services, features, surveys, newsletters, offers, promotions,
            contests, and events; and provide other news or information about us and our
            partners. You can opt-out of receiving marketing communications from us by
            contacting us at <span style={{color:'rgba(36, 121, 168, 1)',fontWeight:'600'}}>bhavna@tatsam.in</span> or
            following the unsubscribe instructions included in our marketing
            communications; (f) process and deliver contest or entries and rewards; (g)
            monitor and analyze trends, usage, and activities in connection with the Sites,
            Applications and Services and for marketing or advertising purposes; (h)
            investigate and prevent fraudulent transactions, unauthorized access to the
            Services, and other illegal activities; (i) personalize the Sites, Applications
            and Services, including by providing features or advertisements that match your
            interests and preferences; and (j) for other purposes for which we obtain your
            consent. </span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>When
            you share with Tatsam your needs and preferences around mental health and the
            services you require, we may use algorithms and automated decision-making to
            identify and surface providers who may be a good fit for you based on the
            information you provide (although you always have the choice to consider more
            providers.) When you seek professional care through one of our affiliated partners,
            we may combine information about you and your clinical activities to provide an
            integrated experience.</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: 'normal'}}><span lang="EN-IN" style={{fontSize: '14.0pt', }}>&nbsp;</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>b.
            For research and development: </span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>We
            are always looking for ways to make our Services smarter, secure, integrated
            and useful to you. We use collective learnings about how people use our
            Services and feedback provided to us to troubleshoot and to identify trends,
            usage, activity patterns and areas for integration and improvement of the
            Services; to analyze and improve our Site and/or Services (including developing
            new products and services); improving safety; managing our communications;
            analyzing our products; performing market research; for peer-reviewed and
            non-peer-reviewed clinical research; and performing data analytics. </span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: 'normal'}}><span lang="EN-IN" style={{fontSize: '14.0pt', }}>&nbsp;</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>c.
            Account Opening Related Data: </span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>In
            order to maintain integrity of the Service, Tatsam must take measures to avoid
            creation of accounts by spammers. Each User Account is unique and associated
            with an individual. Therefore, a unique identifier is generated and associated
            with each user. Contact number is used to facilitate messaging service between
            one or more users of the service. The legal basis for processing is User
            Consent. You are free to delete your User Account and remove that data in the
            account panel of your Tatsam User account. </span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: 'normal'}}><span lang="EN-IN" style={{fontSize: '14.0pt', }}>&nbsp;</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>d.
            Verification: </span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>Such
            data will only be used to contact you with important notifications about Tatsam,
            to send you information related to security, to send you an invitation link to
            create your User Account, to verify your User Account, or to send you password
            recovery links if you enable the option. We may also inform you about new Tatsam
            features in which you might have an interest. You are free, at any given time,
            to opt-out of those features through the account settings panel. In order to
            pursue our legitimate interest of preventing the creation of accounts by spam
            bots or human spammers, Tatsam uses a variety of human verification methods.
            You may be asked to verify using either reCaptcha, Email, or SMS. IP addresses,
            email addresses, and phone numbers provided are saved temporarily in order to
            send you a verification code and to determine if you are a spammer. </span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: 'normal'}}><span lang="EN-IN" style={{fontSize: '14.0pt', }}>&nbsp;</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>e.
            Unauthorized Login: </span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>In
            order to detect unusual activity from your User Account, geolocation and IP
            addresses are used to ascertain only genuine users are using the services, and
            any suspicious activity is reported to the user. The legal basis for processing
            is our legitimate interest to prevent unauthorized use of Service and
            protection of user’s interest, thereby maintaining integrity of the Service.
            Any personal information, so collected, is stored in encrypted form on Tatsam’s
            servers.</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: 'normal'}}><span lang="EN-IN" style={{fontSize: '14.0pt', }}>&nbsp;</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>f.
            Communicating with Tatsam: </span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>Your
            communications with the Company, such as support requests, bug reports, or
            feature requests may be saved by our staff. The legal basis for processing is
            our legitimate interest to troubleshoot more efficiently and improve the
            quality of the Tatsam service.</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: 'normal'}}><span lang="EN-IN" style={{fontSize: '14.0pt', }}>&nbsp;</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>g.
            Payment Information: </span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>The
            Company relies on third parties to process credit card, PayPal, and other
            payment gateways and hence the Company necessarily must share payment
            information with third parties. The legal basis of this processing is the necessity
            to the execution of the contract between you and us.</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: 'normal'}}><span lang="EN-IN" style={{fontSize: '14.0pt', }}>&nbsp;</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>h.
            With your consent: </span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>We
            use information about you where you have given us consent to do so for a
            specific purpose not listed above. For example, we may publish testimonials or
            featured client stories to promote the Services, BUT ONLY with your permission.</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>&nbsp;</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>i.
            To protect our legitimate business interests and legal rights: </span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>Where
            required by law or where we believe it is necessary to protect our legal
            rights, interests and the interests of others, we use information about you in
            connection with legal claims, compliance, accounting, regulatory, and audit
            functions, and disclosures in connection with the acquisition, merger or sale
            of a business.</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>&nbsp;</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>Please
            do note that Tatsam will take reasonable precautions to protect your
            information from loss, misuse or alteration. Please be aware, however, that any
            text, email or other transmission you send unencrypted through the Internet
            cannot be completely protected against unauthorized interception. </span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>If
            you have consented to our use of information about you for a specific purpose,
            you have the right to change your mind at any time, but this will not affect
            any processing that has already taken place.</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>&nbsp;</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><b><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>4.
              DATA USE</span></b></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>Any
            data that we do have will never be shared except under the circumstances
            described below in the SHARING OF PERSONAL INFORMATION. All personal
            information collected from You is solely used for delivering, and improving the
            Service.</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>&nbsp;</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><b><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>5.
              SHARING OF PERSONAL INFORMATION</span></b></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>a.
            Personally Identifiable Information: </span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>We
            will not rent or sell your personally identifiable information to others
            without your consent, although we may share it with partners for the purposes
            described above under “Use of Personal Information”, such as the provision and
            personalization of Services. Such third parties are not authorized to use or
            disclose your information except as necessary to perform Services or comply
            with legal requirements, and are subject to agreements requiring them to
            maintain the confidentiality of any such information.</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>We
            may share your information with outside vendors that we use for a variety of
            purposes, such as to send you communications via emails, send push
            notifications to your mobile device on our behalf, help us analyse use of our
            services. Some of our products, services and databases are hosted by third
            party hosting services providers. We also may use vendors for other projects,
            such as conducting surveys or scheduling private counselling sessions. We may
            share information about you with these vendors only to enable them to perform
            their services.</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>Any
            personally identifiable information you elect to make publicly available on our
            Sites or through the Services will be available to others. If you remove information
            that you have made public on our Sites or through the Services, copies may
            remain viewable in cached and archived pages of our Sites or through the
            Services, or if other users have copied or saved that information.</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: 'normal'}}><span lang="EN-IN" style={{fontSize: '14.0pt', }}>&nbsp;</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>b.
            To Provide Reports to your Employer:</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>In
            case our services are offered to you by your employer, we may provide aggregate
            reports to you employer. However, standard practices of Confidentiality on
            Mental Health Services will be followed by Tatsam in such cases.</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>&nbsp;</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>c.
            Non-Personally Identifiable Information: </span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>We
            may share non-personally identifiable information (such as anonymous usage
            data, referring/exit pages and URLs, platform types, number of clicks, etc.)
            with interested third parties to help us understand the usage patterns for
            certain Services and those of our partners. Tatsam may also share with your Employer
            the outcomes and impact of our Services, which would consist solely of
            non-personally identifiable information, e.g., aggregated and anonymized data.
            To the extent that Tatsam uses artificial intelligence or machine learning on
            the data we collect, Tatsam shall only use non-personally identifiable
            information for these purposes. Non-personally identifiable information may be
            stored indefinitely, unless specially asked by you to be deleted.</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>&nbsp;</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>d.
            Instances Where We Are Required To Share Your Information: </span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>We
            will only disclose the limited user data we possess if we are instructed to do
            so by a fully binding request coming from the competent authorities (legal
            obligation). While we may comply with electronically delivered notices (see
            exceptions below), the disclosed data can only be used in court after we have
            received an original copy of the court order by registered post or in person,
            and provide a formal response.</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>Tatsam
            may from time to time, contest requests if there is a public interest in doing
            so. In such situations, the Company will not comply with the request until all
            legal or other remedies have been exhausted.</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>&nbsp;</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><b><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>6.
              DATA STORAGE</span></b></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>All
            servers used in connection with the provisioning of the Service are located in
            India and are rented by the Company from third party service providers. Only
            employees of the Company have physical or other access to the servers. Data is
            ALWAYS stored in encrypted format on our servers. Offline backups may be stored
            periodically, but these are also encrypted. We do not possess the ability to
            access any user encrypted message content on either the production servers or
            in the backups.</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>We
            are committed to protecting your privacy and data. We encrypt sensitive
            information during transmission and storage. We have put in place appropriate
            security measures to prevent your personal data from being accidentally lost,
            used or accessed in an unauthorized way, altered or disclosed. In addition, we
            limit access to your personal data to those employees, agents, contractors and
            other third parties who have a business need to know. They will only process
            your personal data on our instructions and they are subject to a duty of
            confidentiality. We have put in place procedures to deal with any suspected
            personal data breach and will notify you, other involved parties, and any
            applicable regulator(s) of a breach where we are legally required to do so.</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>&nbsp;</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><b><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>7.
              DATA RETENTION</span></b></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>All
            personal information collected is retained till You delete your user account or
            withdraw consent from profile section on Tatsam website or the mobile app. You
            must appreciate that You may continue to use our services even when you
            withdraw consent for personal information related to visual characteristics.</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>The
            data records are deleted automatically once You request account deletion.&nbsp; However,
            d</span><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%',  color: 'black'}}>eleted data may be retained in our
            backups for up to 14 days.</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>&nbsp;</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><b><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>8.
              RIGHT TO ACCESS, RECTIFICATION, ERASURE, PORTABILITY, AND RIGHT TO LODGE A
              COMPLAINT</span></b></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>Through
            the Service, you can directly access, edit, delete or export personal data
            processed by the Company in your use of the Service. A user may access such
            information through the profile section in our website or the mobile app.</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>Alternatively,
            any user may write an email/post-mail to Data Protection Officer for Tatsam and
            request a copy of their personal information, as held by the Company, or
            request modification or deletion of their personal data.</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>Communication
            Details:</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>Tatsam
            Data Controller and Data Protection Officer</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%',color:'rgba(36, 121, 168, 1)',fontWeight:'600'}}>Name: Yash Pal Mittal</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%',color:'rgba(36, 121, 168, 1)',fontWeight:'600'}}>Email: yash@tatsam.in</span><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}> </span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>Address:
            90b Delhi - Jaipur Expy Sector 18, Gurugram - 122001, Haryana, India.</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>The
            Company, on receiving such request from a user, shall attend to the request
            made by the user and do the needful within 7 days from date of such request.</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>If
            your User Account has been suspended for a breach of our terms and conditions,
            and you would like to exercise the rights related to your personal information,
            you can make a request to our support team.</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>In
            case of violation of your rights, you have the right to lodge a complaint to
            the competent supervisory authority.</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>&nbsp;</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><b><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>9.
              MODIFICATIONS TO PRIVACY POLICY</span></b></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>Tatsam
            reserves the right to periodically review and change this policy from time to
            time and we will notify users who have enabled the notification preference
            about changes to our Privacy Policy Your continued use of Tatsam’s services
            constitutes your agreement to this Privacy Policy and any future revisions. Please
            revisit this page to stay aware of any changes. In general, we only use your
            personal information in the manner described in the Privacy Policy in effect
            when we received that personal information. </span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>&nbsp;</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>For
            revisions to this Privacy Policy that may be materially less restrictive on our
            use or disclosure of personal information you have provided to us, we will make
            reasonable efforts to notify you and obtain your consent before implementing
            revisions with respect to such information.</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>&nbsp;</span></p>
        <p className="MsoNormal" style={{textAlign: 'justify', lineHeight: '150%'}}><span lang="EN-IN" style={{fontSize: '14.0pt', lineHeight: '150%', }}>This
            policy is effective as of 01/10/2021</span></p>
      </div>
      
        <div className="img">
        <img src={footer_img} className="bottom-img" alt="" />
        </div>
        
        </section>
    )
}

export default PrivacyPolicy





