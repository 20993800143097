import React, { useState } from "react";
import "../header/Header.css";
import logo from "../../../../assets/tatsam_collective/logo.png";
import bg from "../../../../assets/tatsam_collective/lemon-bg1.png";
import ResistrationForm from "../resistration-form/ResistrationForm";

function CollectiveHeader() {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <section className="collective-header">
      <img src={bg} alt="" className="collective-header-bg" />
      <div
        className="logo"
        data-aos="fade-right"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out-cubic"
      >
        <img src={logo} alt="" />
      </div>
      <div className="center-data">
        <h1
          data-aos="slide-up"
          data-aos-delay="10"
          data-aos-duration="10"
          className="collective-header-title"
        >
          Tatsam Collective is a unique open space where you can share, talk and
          be reminded that you are not alone
        </h1>
        <h1
          data-aos="slide-up"
          data-aos-delay="10"
          data-aos-duration="10"
          className="collective-header-title-mobile"
        >
          Tatsam Collective is a unique open space where you can share, talk and
          be reminded that you are not alone
        </h1>
        <span
          data-aos="slide-up"
          data-aos-delay="20"
          data-aos-duration="10"
          className="collective-header-content"
        >
          <span className="color-text">Tatsam Collective </span>,hosted on
          Slack, offers many channels covering common life topics
          <br /> where you can anonymously post your thoughts, your experiences
          and the opinions <br />
          that you don’t feel comfortable sharing with other people, but need to
          get out of your head.
          <br /> Read what other people have to say and share your
          perspective/support to be part of the community!
        </span>
        <a
          data-aos="zoom-in"
          data-aos-delay="300"
          data-aos-duration="1000"
          data-aos-offset="-10"
          onClick={togglePopup}
          className="join-button"
          style={{ cursor: "pointer" }}
        >
          Join Now
        </a>
      </div>
      {isOpen && (
        <ResistrationForm
          data-aos="zoom-in-up"
          data-aos-duration="2000"
          data-aos-easing="ease-in-out-cubic"
          handleClose={togglePopup}
        />
      )}
    </section>
  );
}

export default CollectiveHeader;
