import React from "react";
import "./PrivacyTile.css";

function PrivacyTile() {
  return (
    <div className="privacy-tile">
      <div className="container">
        <h4>Your Privacy Is Our Priority</h4>
        <p>
          We believe in the safety of our members so Tatsam Collective is
          anonymously moderated to ensure that
          <br /> responses are not harmful, offensive or inappropriate. All you
          need to do is join and start posting!
        </p>
      </div>
    </div>
  );
}

export default PrivacyTile;
