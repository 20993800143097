import React from "react";
import "./ThankYou.css";
import topRightImg from "./../../assets/tatsam_collective/left_top_img.png";
import logo from "./../../assets/tatsam_collective/logo.png";
import bg from "./../../assets/tatsam_collective/thank-you-bg.png";

function ThankYou() {
  return (
    <section className="thank-you" style={{ backgroundImage: `url(${bg})` }}>
      <a href="index.html">
        <img src={logo} alt="" className="logo" />
      </a>
      <img src={topRightImg} alt="" className="top-right-img" />
      <h1>Thank you for joining our community!</h1>
      <p>
        You will receive an invite in your email within 24 hours.
        <br /> Use the invite to begin posting and engaging with others,
        remember Tatsam Collective is here to listen!{" "}
      </p>
    </section>
  );
}

export default ThankYou;
