import React, { useState } from "react";
import "../why-tatsam-collective/WhyTatsam.css";
import sticker_sheet from "../../../../assets/tatsam_collective/Sticker Sheet.png";
import frame1 from "../../../../assets/tatsam_collective/Frame1.png";
import frame2 from "../../../../assets/tatsam_collective/Frame2.png";
import frame3 from "../../../../assets/tatsam_collective/Frame3.png";
import bg from "../../../../assets/tatsam_collective/why-bg.png";
import rightLeaf from "../../../../assets/tatsam_collective/right_leaf.png";
import ResistrationForm from "../resistration-form/ResistrationForm";

function WhyTatsam() {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const data = [
    {
      img: frame1,
      title: "Our Community",
      subTitle: `We are a mutually supportive community where professionals can anonymously discuss mental health, and relationships, share experiences, get career advice and receive support without judgement. It is a place that allows individuals to prioritise their well-being, and its impact on their professional success to come together and make the best out of their conversations.`,
    },
    {
      img: frame2,
      title: "Our  Belief",
      subTitle: `We believe that when people come together to share their personal experiences, it helps others reflect, learn and unlearn from others’ experiences - making their personal and professional journeys less lonely.`,
    },
    {
      img: frame3,
      title: "Our Pursuit",
      subTitle: `We seek to normalise conversations around mental health and wellbeing that are label-free and judgement-free. Through peer-led community support and the expertise of qualified therapists, we wish to create a space where you can not only take the charge and define your own personal journey but also be reminded of your strength and what matters to you the most.`,
    },
  ];

  return (
    <section
      className="why-tatsam"
      style={{
        background: `url(${bg})`,
        backgroundPosition: "0px -140px",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <img src={rightLeaf} alt="" className="right-leaf" />
      <div className="container">
        <div className="row">
          <div
            className="column-1"
            data-aos="fade-right"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out-cubic"
          >
            <span>Why Tatsam Collective?</span>
            <p>
              The intent is super simple: to enrich our network and to help each
              other as we try to attain our own version of work-life harmony and
              a meaningful life.
            </p>
            <a onClick={togglePopup} className="join-button">
              Join Now
            </a>
          </div>
          <div
            className="column-2"
            data-aos="fade-left"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out-cubic"
          >
            {data.map((tile) => {
              return (
                <div className="tile">
                  <div className="img-box">
                    <img src={sticker_sheet} className="sticker-sheet" alt="" />
                    <img src={tile.img} alt="" className="frame1" />
                  </div>
                  <div className="content">
                    <span>{tile.title}</span>
                    <p>{tile.subTitle}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {isOpen && (
        <ResistrationForm
          data-aos="zoom-in-up"
          data-aos-duration="2000"
          data-aos-easing="ease-in-out-cubic"
          handleClose={togglePopup}
        />
      )}
    </section>
  );

  function Tile() {
    return (
      <div className="tile">
        <div className="img-box">
          <img src={sticker_sheet} className="sticker-sheet" alt="" />
          <img src={frame1} alt="" className="frame1" />
        </div>
        <div className="content">
          <h3>Our Community</h3>
          <p>
            we are Modern professionals who have an ambition to lead a holistic
            balanced life, AND want to actively work towards it.
          </p>
        </div>
      </div>
    );
  }
}

export default WhyTatsam;
