import React, { useState, useEffect } from "react";
import "./ResistrationForm.css";
import bg from "../../../../assets/tatsam_collective/Registration Form.png";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useHistory } from "react-router-dom";
// import {
//   uniqueNamesGenerator,
//   adjectives,
//   starWars,
//   names,
//   NumberDictionary
// } from "unique-names-generator";

function ResistrationForm({ handleClose, formName }) {
  console.log(formName);

  const history = useHistory();
  function routeChange() {
    history.push("/thank-you");
  }
  // const {
  //   uniqueNamesGenerator,
  //   adjectives,
  //   names,
  // } = require("unique-names-generator");

  // const numberDictionary = NumberDictionary.generate({ min: 10, max: 99 });
  // const randomName = uniqueNamesGenerator({
  //   dictionaries: [names, adjectives, numberDictionary],
  //   length: 3,
  //   separator: "_",
  // });

  const [form, setForm] = useState(formName);
  const [inputField, setInputField] = useState({
    username: "",
    email: "",
  });

  const [inputErrorMessage, setInputErrorMessage] = useState("");

  const inputsHandler = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setInputField({ ...inputField, [name]: value });
    setInputErrorMessage("");
  };

  const submit = () => {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(inputField.email)) {
      onSubmit(inputField.email, inputField.username, form);
      setTimeout(() => {
        handleClose();
      }, 500);
    } else {
      setInputErrorMessage("! Please Enter Valid Email");
    }
  };

  const onSubmit = (email, username, formName) => {
    console.log(email, username, formName);

    window.analytics.identify(
      email,
      {
        email: email,
      },
      {
        integrations: {
          All: false,
          "Customer.io": true,
        },
      }
    );
    window.analytics.identify(
      email,
      {
        email: email,
        firstName: username,
      },
      {
        integrations: {
          All: false,
          MailChimp: true,
        },
      }
    );
    window.analytics.track(
      "Collective Access Requested",
      {
        source: "Tatsam Collective Landing Page",
        requestedFor: formName,
      },
      {
        integrations: {
          All: true,
        },
      }
    );
    routeChange();
  };

  function toggle(elementId, btnId) {
    var ele = document.getElementById(elementId);
    var btn = document.getElementById(btnId);
    if (ele.style.display == "block") {
      ele.style.display = "none";
      btn.innerHTML = "...More";
    } else {
      ele.style.display = "block";
      btn.innerHTML = "Less";
    }
  }
  return (
    <div className="resistration-form">
      <div className="layer" onClick={handleClose}></div>
      <div
        className="form"
        data-aos="zoom-in"
        data-aos-duration="400"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container">
          <div className="local-form">
            <span>Registration Form</span>
            <p>Don’t miss out- register now!</p>
            <div className="input-field">
              <span className="input-label">Username</span>
              <input
                type="text"
                placeholder="Enter your username.."
                name="username"
                value={inputField.username}
                onChange={inputsHandler}
                required
              />
              <span className="info-msg">
                Tatsam Collective is an anonymous community, so make sure to
                choose a username
                <span id="more">
                  that cannot be easily linked back to you. Your username will
                  be visible to others, so pick something unique. Do not use
                  punctuation, or personal information that can identify you,
                  like your real name or date of birth. You can use colours,
                  animals, or anything else. Get creative!
                </span>
                <a
                  href="#"
                  id="btn-1"
                  style={{ textDecoration: "none" }}
                  onClick={() => {
                    toggle("more", "btn-1");
                  }}
                >
                  ...More
                </a>
              </span>
            </div>
            <div className="input-field">
              <span className="input-label">Email</span>
              <input
                type="email"
                placeholder="Enter your email.."
                name="email"
                value={inputField.email}
                onChange={inputsHandler}
                required
              />
              <span className="info-msg">
                Your email address will only be used to keep you updated and to
                allow you to
                <span id="more-1">
                  sign in to your account. Email addresses will be completely
                  confidential so as to not compromise your anonymity.
                </span>
                <a
                  href="#"
                  id="btn-2"
                  style={{ textDecoration: "none" }}
                  onClick={() => {
                    toggle("more-1", "btn-2");
                  }}
                >
                  ...More
                </a>
              </span>
              <span className="error-msg">{inputErrorMessage}</span>
            </div>

            <a onClick={submit} className="get-started-btn">
              Confirm Registration
            </a>
          </div>
          <AiOutlineCloseCircle
            onClick={handleClose}
            style={{
              fontSize: "35px",
              position: "absolute",
              top: "15px",
              right: "15px",
              color: "rgba(36, 121, 168, 1)",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ResistrationForm;
